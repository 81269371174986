import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Submenu extends Vue {
  @Prop({ required: false }) open!: boolean
  overlay = true
  fullheight = true
  fullwidth = false
  right = true
}
